import { useCallback, useContext, useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
    HomeContext,
    HouseContext,
    OrderContext,
    RoomContext,
} from "../House/House";
import "./Home.css";
import { useNavigate, useParams } from "react-router-dom";
import Info from "../../icons/Info.svg";
import InfoIcon from "./InfoIcon.svg";
import CloseIcon from "./CloseIcon.svg";
import { ModalContext } from "../Container/Container";
import SectionModal from "./SectionModal";
import detailedPlanningIcon from "./detail_planning.svg";
import { v4 as uuidv4 } from "uuid";

function Product({ thumbnail, title, onClick, selected, selectable }) {
    let className = "product";

    if (selected) {
        className += " selected";
    }

    if (!selectable) {
        className += " disabled";
    }

    return (
        <button onClick={onClick} className={className}>
            {thumbnail ? (
                <img src={thumbnail} alt={title} />
            ) : (
                <div className="image-placeholder"></div>
            )}

            <h3>{title}</h3>
        </button>
    );
}

function ProductSection({
    name,
    description,
    products,
    packet,
    packetPrice,
    optional,
}) {
    const { order, setOrder } = useContext(OrderContext);
    const { room } = useContext(RoomContext);
    const { setModal } = useContext(ModalContext);
    const { orderId } = useParams();
    const { home } = useContext(HomeContext);
    let filteredProducts = [];

    if (Array.isArray(products)) {
        filteredProducts = products.filter((p) => p.pakett?.name === packet);
    } else {
        const arr = Object.keys(products).map((key) => {
            return products[key];
        });
        filteredProducts = arr.filter((p) => p.pakett?.name === packet);
    }

    // Find selected product in this section
    const selectedProduct = useCallback(() => {
        const o_room = order?.rooms?.find((x) => x.name === room.room_name);

        if (!o_room) {
            return null;
        }

        const section = o_room.sections?.find((x) => x.name === name);

        if (!section) {
            return null;
        }

        return section.product;
    }, [name, order, room]);

    // Check if a product is selectable
    const isSelectable = (product) => {
        const selectedProducts = order?.rooms
            ?.flatMap((room) => room.sections)
            .flatMap((section) => section.product);

        if (!selectedProducts) {
            return true;
        }

        return !selectedProducts.some(
            (selected) =>
                selected &&
                selected.unsuitable_products?.includes(product.product)
        );
    };

    // Select a product
    const selectProductCb = (product) => {
        if (!room) {
            return;
        }

        // Does the product already exist, and section is optional, in which case remove the product
        if (optional && selectedProduct()?.product === product.product) {
            setOrder({
                ...order,
                rooms: order?.rooms.map((x) => {
                    if (x.name === room.room_name) {
                        return {
                            ...x,
                            sections: x.sections.filter((y) => y.name !== name),
                        };
                    }

                    return x;
                }),
            });

            return;
        }

        // Does the room exist in the order?
        const o_room = order?.rooms?.find((x) => x.name === room.room_name);

        // If not, create it
        if (!o_room) {
            const newRoom = {
                name: room.room_name,
                sections: [
                    {
                        name,
                        product,
                    },
                ],
            };

            setOrder({ ...order, rooms: [...(order?.rooms ?? []), newRoom] });
            return;
        }

        // If yes, does the section exist in the room?
        const section = o_room.sections?.find((x) => x.name === name);

        // If the section does not exist, create it
        if (!section) {
            setOrder({
                ...order,
                rooms: order?.rooms.map((x) => {
                    if (x.name === room.room_name) {
                        return {
                            ...x,
                            sections: [
                                ...x.sections,
                                {
                                    name,
                                    product,
                                },
                            ],
                        };
                    }

                    return x;
                }),
            });

            return;
        }

        // If the section exists, update it
        setOrder({
            ...order,
            rooms: order?.rooms.map((x) => {
                if (x.name === room.room_name) {
                    return {
                        ...x,
                        sections: x.sections.map((y) => {
                            if (y.name === name) {
                                return {
                                    ...y,
                                    product,
                                };
                            }

                            return y;
                        }),
                    };
                }

                return x;
            }),
        });

        // Check if order has any unsuitable products in other sections
        const unsuitableSections = order?.rooms
            ?.flatMap((room) => room.sections)
            .filter((section) =>
                product.unsuitable_products?.includes(section.product?.product)
            );

        // If it does, remove the unsuitable products and replace them with the next product
        if (unsuitableSections?.length) {
            setOrder((prevOrder) => ({
                ...prevOrder,
                rooms: prevOrder?.rooms.map((x) => {
                    if (x.name === room.room_name) {
                        return {
                            ...x,
                            sections: x.sections.map((y) => {
                                if (
                                    unsuitableSections.some(
                                        (s) => s.name === y.name
                                    )
                                ) {
                                    const sectionProducts = room.sections
                                        .find((s) => s.name === y.name)
                                        .products.filter(
                                            (p) =>
                                                !product.unsuitable_products?.includes(
                                                    p.product
                                                )
                                        );

                                    return {
                                        ...y,
                                        product: sectionProducts[0] ?? null,
                                    };
                                }

                                return y;
                            }),
                        };
                    }

                    return x;
                }),
            }));
        }
    };

    const selectProduct = useCallback(selectProductCb, [
        name,
        order,
        setOrder,
        room,
        optional,
        selectedProduct,
    ]);

    const showModal = (_) => {
        setModal({
            title: name,
            body: (
                <SectionModal
                    homeType={home?.home_type}
                    products={filteredProducts}
                />
            ),
        });
    };

    const selectedProductIsDefaultProduct = () => {
        if (optional) {
            return false;
        }

        return selectedProduct()?.product === filteredProducts[0].product;
    };

    const price = () => {
        let val = parseFloat(selectedProduct()?.hind);

        if (selectedProduct()?.hinnad) {
            selectedProduct()?.hinnad.forEach((priceObj) => {
                if (priceObj.type === home?.home_type) {
                    val = parseFloat(priceObj.price);
                }
            });
        }

        if (isNaN(val) || selectedProductIsDefaultProduct()) {
            return 0;
        }

        return val;
    };

    useEffect(() => {
        if (!selectedProduct() && products.length && !orderId) {
            selectProduct(products[0]);
        }
    }, [order, orderId, products, selectProduct, selectedProduct]);

    if (!filteredProducts.length) {
        return null;
    }

    return (
        <>
            <div className="section">
                <div className="section-heading">
                    <div>
                        <h3>{name}</h3>
                        <button type="button" onClick={showModal}>
                            <span>
                                <img src={Info} alt="" />
                            </span>
                            Lisainfo
                        </button>
                    </div>

                    <div>+ {price()}&euro;</div>
                </div>

                {description.length > 0 && (
                    <div className="section-description">{description}</div>
                )}

                <div className="products">
                    {filteredProducts &&
                        filteredProducts.map((product, index) => {
                            return (
                                <Product
                                    key={index}
                                    selected={
                                        selectedProduct()?.product ===
                                        product.product
                                    }
                                    {...product}
                                    onClick={() =>
                                        isSelectable(product)
                                            ? selectProduct(product)
                                            : null
                                    }
                                    selectable={isSelectable(product)}
                                />
                            );
                        })}
                </div>
            </div>

            <hr />
        </>
    );
}

function ProductSections({ packet, packetPrice }) {
    const { room } = useContext(RoomContext);

    return (
        <div className="sections">
            {room.sections.length > 0 &&
                room.sections.map((section, index) => (
                    <ProductSection
                        key={index}
                        packet={packet}
                        packetPrice={packetPrice}
                        {...section}
                    />
                ))}
        </div>
    );
}

function TotalPrice({ packetPrice }) {
    const { order } = useContext(OrderContext);
    const { home } = useContext(HomeContext);

    const totalAmount = () => {
        let total = 0;
        let basePrice = 0;
        let basePriceAdded = false;

        order?.rooms?.forEach((room) => {
            const products = room?.sections?.flatMap(
                (section) => section?.product ?? []
            );
            const packets = products?.flatMap(
                (product) => product?.pakett ?? []
            );

            if (!basePriceAdded && packets.length > 0 && packets[0]?.price) {
                basePrice = packets[0].price;
                basePriceAdded = true;
            }

            room.sections?.forEach((section) => {
                if (section.product) {
                    let price = parseFloat(section.product.hind);

                    if (
                        section.product?.hinnad &&
                        section.product?.hinnad.length > 0
                    ) {
                        section.product?.hinnad.forEach((priceObj) => {
                            if (priceObj.type === home?.home_type) {
                                price = parseFloat(priceObj.price);
                            }
                        });
                    }

                    if (isNaN(price) || section.product.is_default) return;

                    if (/^\d+$/.test(price)) {
                        total += parseFloat(price);
                    }
                }
            });
        });

        total += basePrice;

        return total;
    };

    return (
        <div className="total-price">
            <div>Kogusumma</div>
            <div>{totalAmount()}&euro;</div>
        </div>
    );
}

function Pagination() {
    const { home } = useContext(HomeContext);
    const { room, setRoom } = useContext(RoomContext);
    const name = room.room_name;
    const firstRoom = home?.rooms[0].room_name === name;
    const lastRoom = home?.rooms[home.rooms.length - 1].room_name === name;
    const navigate = useNavigate();
    const { houseId, homeId, orderId } = useParams();

    const goToPrev = () => {
        const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
        const prevIndex = currentIndex - 1;
        const prevRoom = home?.rooms[prevIndex];

        setRoom(prevRoom);
    };

    const getPrevRoom = () => {
        const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
        const prevIndex = currentIndex - 1;

        return home?.rooms[prevIndex];
    };

    const goToNext = () => {
        const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
        const nextIndex = currentIndex + 1;
        const nextRoom = home.rooms[nextIndex];

        setRoom(nextRoom);
    };

    const getNextRoom = () => {
        const currentIndex = home?.rooms.findIndex((x) => x.room_name === name);
        const nextIndex = currentIndex + 1;

        return home?.rooms[nextIndex];
    };

    const viewSummary = (_) => {
        navigate(`/house/${houseId}/${homeId}/${orderId}/summary`);
    };

    return (
        <div className="pagination">
            {!firstRoom && (
                <button className="back" onClick={goToPrev}>
                    <span>{"<-"}</span>
                    {getPrevRoom().room_name}
                </button>
            )}

            {!lastRoom && (
                <button className="forward" onClick={goToNext}>
                    {getNextRoom().room_name}
                    <span>{"->"}</span>
                </button>
            )}

            {lastRoom && (
                <button className="forward" onClick={viewSummary}>
                    Kokkuvõte
                    <span>{"->"}</span>
                </button>
            )}
        </div>
    );
}

function Sidebar() {
    const { house } = useContext(HouseContext);
    const { home } = useContext(HomeContext);
    const { room } = useContext(RoomContext);
    const { order, setOrder } = useContext(OrderContext);
    const [packet, setPacket] = useState(null);
    const [fullPacket, setFullPacket] = useState(null);
    const { setModal } = useContext(ModalContext);
    const navigate = useNavigate();

    const packetPrice = useCallback(
        (name) => {
            if (!room || !room.sections) {
                return 0;
            }

            const products =
                room?.sections?.flatMap((section) => section.products) ?? [];
            const foundPacket =
                products
                    ?.flatMap((product) => product.pakett)
                    .find((p) => p?.name === name) ?? [];

            return foundPacket?.price ?? 0;
        },
        [room]
    );

    const fullPacketData = useCallback(
        (name) => {
            if (!room || !room.sections) {
                return 0;
            }

            const products =
                room?.sections?.flatMap((section) => section.products) ?? [];
            const foundPacket =
                products
                    ?.flatMap((product) => product.pakett)
                    .find((p) => p?.name === name) ?? [];

            return foundPacket ?? null;
        },
        [room]
    );

    const packets = useCallback(() => {
        if (!room || !room.sections) {
            return [];
        }

        const products = room.sections?.flatMap((section) => section.products);
        const _packets = [];

        for (const p of products) {
            if (p.pakett && !_packets.includes(p.pakett.name)) {
                _packets.push(p.pakett.name);
            }
        }

        return _packets.map((p) => {
            const price = packetPrice(p);

            return {
                value: p,
                label:
                    parseFloat(price) === 0 ? p : `${p} - ${packetPrice(p)}€`,
            };
        });
    }, [room, packetPrice]);

    useEffect(() => {
        if (packets().length && !packet) {
            setPacket(packets()[0].value);
        }

        setFullPacket(fullPacketData(packet));
    }, [packets, packet, setPacket, fullPacketData]);

    if (!home) {
        return null;
    }

    const homeOptions = () => {
        return house?.homes.map((home) => {
            return {
                value: home.id,
                label: `Korter ${home.number} - ${
                    home?.room_count ?? 1
                } tuba - ${home.size} m²`,
            };
        });
    };

    const changeHome = (value) => {
        navigate(`/house/${house.id}/${value}`);
    };

    const defaultProductOfSectionInPacket = (section, packet) => {
        const productsInSection = room.sections?.find(
            (s) => s.name === section && !s.optional
        )?.products;
        const productsInPacket = productsInSection?.filter(
            (p) => p.pakett.name === packet
        );

        return productsInPacket?.length > 0 ? productsInPacket[0] : null;
    };

    const changePacket = (value) => {
        setPacket(value);
        setOrder({
            ...order,
            rooms: order.rooms?.map((room) => {
                return {
                    ...room,
                    sections: room.sections?.map((section) => {
                        return {
                            ...section,
                            product: defaultProductOfSectionInPacket(
                                section.name,
                                value
                            ),
                        };
                    }),
                };
            }),
        });
    };

    const showModal = (_) => {
        setModal({
            title: fullPacket.name,
            body: (
                <div
                    className="packet-modal"
                    dangerouslySetInnerHTML={{ __html: fullPacket.description }}
                />
            ),
        });
    };

    return (
        <div className="sidebar">
            <Dropdown
                options={homeOptions()}
                selectedValue={home.id}
                onChange={changeHome}
                disabled={true}
            />
            <Dropdown
                options={packets()}
                selectedValue={packet}
                onChange={changePacket}
            />
            <button type="button" className="packet-info" onClick={showModal}>
                <span>
                    <img src={Info} alt="" />
                </span>
                Paketi kirjeldus
            </button>
            <hr />
            <ProductSections
                packetPrice={packetPrice(packet)}
                packet={packet}
            />
            <TotalPrice packetPrice={packetPrice(packet)} />
            <hr />
            <Pagination />
        </div>
    );
}

function Planning({ home, signs, setSigns }) {
    if (!home || !home.planning.image) {
        return null;
    }

    const legends =
        home?.planning?.legend ??
        home.planning.legend.filter((l) => l.name !== "" && l.color !== "") ??
        [];

    return (
        <div className="planning">
            <div
                className={
                    legends.length > 0
                        ? "planning-content"
                        : "planning-content no-signs"
                }
            >
                <div className="planning-legend">
                    <div>
                        {home.planning?.legend &&
                            home.planning?.legend?.map((item, index) => (
                                <div key={index}>
                                    <div
                                        className="color"
                                        style={{ backgroundColor: item.color }}
                                    />
                                    <div className="label">{item.name}</div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="planning-image">
                    <img src={home.planning?.image} alt="" />
                </div>
            </div>

            {signs === true && (
                <button type="button" onClick={() => setSigns(!signs)}>
                    <img src={InfoIcon} alt="Tingmärgid" />
                    Tingmärgid
                </button>
            )}
        </div>
    );
}

function Signs({ sections, onClose }) {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        if (sections.length) {
            setActiveSection(sections[0].name);
        }
    }, [sections, setActiveSection]);

    const items = () => {
        return sections.find((x) => x.name === activeSection)?.signs;
    };

    if (!items()) {
        return null;
    }

    return (
        <div className="planning-signs">
            <div className="planning-signs-heading">
                <button type="button" onClick={() => onClose()}>
                    <img src={CloseIcon} alt="" />
                    Sulge tingmärgid
                </button>

                <div className="planning-signs-sections">
                    {sections?.map((section, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveSection(section.name)}
                            type="button"
                            className={
                                activeSection === section.name ? "selected" : ""
                            }
                        >
                            {section.name}
                        </button>
                    ))}
                </div>
            </div>

            <div className="planning-signs-items">
                {items().map((x, i) => (
                    <div key={i} className="planning-signs-item">
                        <img src={x.image} alt="" />
                        <h3>{x.name}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}

function Content() {
    const { home } = useContext(HomeContext);
    const { order } = useContext(OrderContext);
    const { room } = useContext(RoomContext);
    const { setModal } = useContext(ModalContext);
    const room_order = order?.rooms?.find((r) => r.name === room.room_name);

    const handleSigns = (v) => {
        setModal({
            title: home.title,
            sidebar: v ? (
                <Signs
                    sections={home.planning.signs}
                    onClose={() => handleSigns(false)}
                />
            ) : (
                false
            ),
            body: <Planning house={home} signs={v} setSigns={handleSigns} />,
        });
    };

    const viewPlanning = (_) => {
        setModal({
            title: home.title,
            sidebar: false,
            body: <Planning home={home} signs={false} setSigns={handleSigns} />,
        });
    };

    if (!room_order) {
        return null;
    }

    const images = room_order.sections
        .filter((section) => section.product)
        .map((section) => {
            return section.product.image;
        })
        .filter((image) => {
            return typeof image === "string";
        });

    return (
        <div className="content-container">
            <div className="content">
                {images.map((image, index) => (
                    <img
                        key={uuidv4()}
                        src={image}
                        alt=""
                        style={{ zIndex: index + 1 }}
                    />
                ))}

                {images.length !== 0 && (
                    <button type="button" onClick={viewPlanning}>
                        <img src={detailedPlanningIcon} alt="Planeering" />
                        Vaata planeeringut
                    </button>
                )}

                {home?.disclaimer?.length > 0 && (
                    <div className={"disclaimer"}>{home.disclaimer}</div>
                )}
            </div>
        </div>
    );
}

function Home() {
    return (
        <div className="home">
            <Sidebar />
            <Content />
        </div>
    );
}

export default Home;

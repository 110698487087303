import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";

const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById("root"));

window.API_URL = "https://reterraconfig.rktv.ee/wp-json/reterra";

if (window.location.href.includes("localhost")) {
  window.API_URL = "https://reterraconfig.ddev.site/wp-json/reterra";
}

root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>
);

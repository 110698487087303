import { useState } from "react";
import "./Dropdown.css";
import CharionIcon from "./CharionIcon.svg";

function DropdownOption({ children, onClick, value }) {
	return (
		<button onClick={() => onClick(value)}>{children}</button>
	)
}

function Dropdown({ options, selectedValue, onChange, disabled }) {
	const [open, setOpen] = useState(false);

	const dropdownLabel = () => {
		if (selectedValue) {
			return options.find(option => option.value === selectedValue)?.label;
		}

		return "Vali ..."
	}

	const onSelect = (value) => {
		onChange(value);
		setOpen(false);
	}

	return (
		<div className="dropdown">
			<button onClick={() => setOpen(!open)}>
				{dropdownLabel()}
				{!disabled && <img src={CharionIcon} alt="" />}
			</button>

			{open && !disabled && (
				<div className="dropdown-options">
					{options.map((option, index) => (
						<DropdownOption key={index} onClick={onSelect} {...option}>{option.label}</DropdownOption>
					))}
				</div>
			)}
		</div>
	);
}

export default Dropdown;

import { useEffect, useState } from "react";
import axios from "axios";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const filteredOrders = orders?.filter((o) => {
    return o.order?.email?.length;
  });

  const computePackages = (order) => {
    const allPackages = order?.order?.rooms
      ?.flatMap((room) =>
        room?.sections?.flatMap((section) => section?.product?.pakett?.name)
      )
      .filter((x) => x !== undefined);
    return allPackages?.filter((v, i) => allPackages.indexOf(v) === i) ?? [];
  };

  const computeProducts = (order) => {
    const allProducts = order?.order?.rooms
      ?.flatMap((room) =>
        room?.sections?.flatMap((section) => {
          return {
            name: section?.product?.name,
            info: section?.product?.info,
          };
        })
      )
      .filter((x) => x.name !== undefined);
    return allProducts?.filter((v, i) => allProducts.indexOf(v) === i) ?? [];
  };

  useEffect(() => {
    axios
      .get(`${window.API_URL}/orders`)
      .then(({ data }) => {
        setOrders(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div style={{ padding: "50px" }}>
      <h2>Tellimused</h2>
      <a href={`${window.API_URL}/orders/csv`}>
        Lae alla kõik tellimused .csv failina
      </a>

      <table border="1" style={{ marginTop: 50 }}>
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Saatja e-post</th>
            <th>Telefoninumber</th>
            <th>Kontor/Korter</th>
            <th>Maja</th>
            <th>Pakett</th>
            <th>Valitud tooted</th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order, index) => (
            <tr key={index}>
              <td>{order.order?.name ?? "-"}</td>
              <td>{order.order?.email ?? "-"}</td>
              <td>{order.order?.number ?? "-"}</td>
              <td>
                <a
                  href={`https://reterraconfig.rktv.ee/wp-admin/post.php?post=${order.order?.homeId}&action=edit`}
                >
                  {order.home_title + ` (nr ${order.home_number})` ?? "-"}
                </a>
              </td>
              <td>
                <a
                  href={`https://reterraconfig.rktv.ee/wp-admin/post.php?post=${order.order?.houseId}&action=edit`}
                >
                  {order.house_title ?? "-"}
                </a>
              </td>
              <td>{computePackages(order).join(", ")}</td>
              <td>
                <ul>
                  {computeProducts(order)?.map((item, i) => (
                    <li key={i}>
                      <strong>{item.name}</strong>
                      <ul>
                        {Array.isArray(item.info) &&
                          item.info?.map((info, j) => (
                            <li key={j}>
                              {info.show_key === true && info.key + ":"}{" "}
                              {info.value}
                            </li>
                          ))}
                      </ul>
                      <hr />
                    </li>
                  ))}
                </ul>
              </td>
              <td>
                <a href={`${window.API_URL}/pdf/${order.id}`}>Lae alla PDF</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
